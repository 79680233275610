import {ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import * as auth from "./auth"
import { onError } from "apollo-link-error";
import {useHistory} from "react-router-dom";

const httpLink = createHttpLink({
    //uri: "http://"+window.location.hostname+":8000/graphql",
    //uri: "https://api.partners.banksii.pt/public/graphql",
    uri: window.location.protocol+"//api."+window.location.hostname+"/public/graphql",
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    //console.log(networkError.name)
    //alert("STOP")
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );

           /* if (message.includes("not authenticated")) {
                Router.replace("/login");
            } else {
                console.log("dispatch");
                snackbarStore.dispatch.snackbar.handleOpen(message);
            }*/
        });
    if (networkError) {
        //auth.logout()
        //window.location.href='/'
        //console.log(`[Network error]: ${networkError}`);
    }
});


const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = auth.token();
    // return the headers to the context so httpLink can read them
    if(token!==null){
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${token}`,
            }
        }
    }else{
        return {
            headers: {
                ...headers
            }
        }
    }

});

export default new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    cache: new InMemoryCache(),
    connectToDevTools: true,
});

