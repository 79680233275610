import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    Grid
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import DataTable from "react-data-table-component"
import {useLazyQuery, useQuery} from "@apollo/client";
import {gql} from "@apollo/client/core";
import Button from "@material-ui/core/Button";

export default function CustomerList(props){

    const GetCustomers = gql `
        query getCustomers($perPage:Int!, $page:Int! ){
            Customers(perPage: $perPage, page:$page){
                data {
                    id
                    name
                    email
                    drID
                    password
                }
            page
            perPage
            total
            totalPages
            }
        }

    `;

    const {data, loading : isLoading, fetchMore } = useQuery(GetCustomers , {variables: {"perPage": 10, "page": 0}, fetchPolicy: "cache-and-network"});



    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(()=>{
        if(!isLoading){
            if (fetchMore) {
                fetchMore({
                    updateQuery: update,
                    variables: {"perPage": perPage, "page": page}
                })
            }
        }
    }, [page, perPage]);

    function handleClickLogin({drID, password}){
        window.open(`https://drosera.pt/partner?drID=${drID}&token=${password}`, '_blank');
    }

    const columns = [
        {
            name: 'ID',
            selector: 'drID',
            sortable: true,
        },
        {
            name: 'Nome',
            selector: 'name',
            sortable: true,
        },{
            name: 'Email',
            selector: 'email',
            sortable: true,
        },{
            name: 'Entrar',
            cell:(row)=><Button onClick={()=>handleClickLogin(row)} variant={"contained"} color={"primary"}>Aceder a portal</Button>,
            sortable: true,
        },
    ];

    function loadMore(newPage=page, newPerPage=perPage){
        setPage(newPage)
        setPerPage(newPerPage)
    }

    function update(previousResult, { fetchMoreResult }){
        if (!fetchMoreResult) {
            return previousResult;
        }
        return { ...fetchMoreResult }
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                            Listagem de cliente
                        </Typography>
                        <Divider/>
                        {!data?(
                            <center>
                                <CircularProgress />
                            </center>
                        ):<DataTable
                            columns={columns}
                            data={data.Customers.data}
                            pagination
                            paginationServer
                            paginationTotalRows={data.Customers.total}
                            paginationDefaultPage={data.Customers.page}
                            onChangeRowsPerPage={(e)=>loadMore(page, e)}
                            onChangePage={(e)=>loadMore(e, perPage)}
                        />}

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}