import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import PrivateRoute from "./services/PrivateRoute";
import CustomerAdd from "./pages/Customer/Add";
import CustomerList from "./pages/Customer/List";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute path="/dashboard" component={()=>"dashboard"} />
            <PrivateRoute exact path="/customer/add" component={CustomerAdd} />
            <PrivateRoute exact path="/customer" component={CustomerList} />
        </Switch>
    </BrowserRouter>
)

export default Routes;