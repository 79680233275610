

export function setLogin  (name, token){
    localStorage.setItem(TOKEN_KEY, token)
    localStorage.setItem(NAME_KEY, name)
    return null
}

export const TOKEN_KEY ="@partner-Token";
export const NAME_KEY ="@partner-User";
export const PARTNER_KEY ="@partner-Nome";



export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null && localStorage.getItem(NAME_KEY) !== null;

export const name = () => localStorage.getItem(NAME_KEY);

export const token = () => localStorage.getItem(TOKEN_KEY);

export const getSession = ()=>{
    if(isAuthenticated()){
        return {
            status: true,
            data: {
                user: localStorage.getItem(NAME_KEY),
                token: localStorage.getItem(TOKEN_KEY)
            }
        }
    }else{
        return {
            status: false,
            data: {
                user: "",
                token: ""
            }
        }
    }
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(NAME_KEY);
    localStorage.removeItem(PARTNER_KEY);
};
