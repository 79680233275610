import React from 'react';

import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider}  from "@material-ui/core/styles"
import { SnackbarProvider } from 'notistack';
import Routes from "./Routes";
import { ApolloProvider } from '@apollo/client/react';
import theme from "./theme";
import api from "./services/api";

function App() {
  return (
      <ApolloProvider client={api}>
          <SnackbarProvider maxSnack={5}>
              <ThemeProvider theme={theme}>
                <div className="App">
                  <CssBaseline/>
                    <Routes/>
                </div>
              </ThemeProvider>
          </SnackbarProvider>
      </ApolloProvider>
  );
}

export default App;
