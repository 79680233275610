import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Card, CardContent, Divider, Grid, TextField} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import {useLazyQuery, useMutation} from "@apollo/client";
import {gql} from "@apollo/client/core";
import {useSnackbar} from "notistack";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: '20px',
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

export default function CustomerAdd(props){
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const { enqueueSnackbar } = useSnackbar();
    const [name, setName] = useState("");
    const [nif, setNif] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailHost, setEmailHost] = useState("");
    const [emailSend, setEmailSend] = useState("");
    const [emailName, setEmailName] = useState("");
    const [emailPassword, setEmailPassword] = useState("");
    const [emailPort, setEmailPort] = useState("");
    const [disableButtons, setDisableButtons]=useState(false)

    const GetInfo = gql `
    query GetInfo{
            Info{
                Token, DRID
            }
        }
    `;

    const [getInfo, {loading: load, data: info}] = useLazyQuery(GetInfo, { fetchPolicy: "network-only"})
    const [register] = useMutation(gql `
        mutation NewCustomer ($name: String!, $email:String!, $phoneNumber:String!, $DRID: String, $Password:String){
            NewCustomer (
                name: $name,
                email:$email,
                phoneNumber:$phoneNumber,
                DRID:$DRID,
                Password:$Password
            )
            {id message error}
        }
    `);


    useEffect(()=>{
        if(activeStep===2){
            getInfo();
        }
    }, [activeStep])


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    async function registerCustomer(){
        if(name!=="" && email!=="" && phoneNumber!==""){
            const res = await register({variables:{
                name, email, phoneNumber, DRID:info.Info.DRID,  Password:info.Info.Token
            }});
            enqueueSnackbar(res.data.NewCustomer.message, {variant:res.data.NewCustomer.error===0?"success":"error"});
            if(res.data.NewCustomer.error===0){
                setDisableButtons(true);
            }
        }else{
            enqueueSnackbar('Algumas das informações do cliente não foram preenchidas', { variant:"error" });
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={12}>
                <Card>

                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className={"header-title"}>
                            Registo de cliente
                        </Typography>
                        <Divider/>
                        <div className={classes.root}>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Introdução</b></Typography></StepLabel>
                                    <StepContent>
                                        <Typography>Este assistente irá auxiliar na criação do seu novo cliente</Typography>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Informações Basicas</b></Typography></StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2} sm={12} md={6}>
                                            <Grid item sm={6} md={12}>
                                                <Grid container sm={12}>
                                                    <Grid item sm={4}>
                                                        <TextField value={name} onChange={e=>setName(e.target.value)} label={"Nome"} type={"text"} variant={"standard"} name={"name"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={email} onChange={e=>setEmail(e.target.value)} label={"Email"} type={"email"} variant={"standard"} name={"email"}/>
                                                    </Grid>
                                                    <Grid item sm={4}>
                                                        <TextField value={phoneNumber} onChange={e=>setPhoneNumber(e.target.value)} label={"Numero telefone"} type={"number"} variant={"standard"} name={"phoneNumber"}/>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                                <Step>
                                    <StepLabel><Typography className={"header-title"}><b>Resumo</b></Typography></StepLabel>
                                    <StepContent>
                                        <Grid container spacing={2} sm={6}>
                                            <Grid item sm={12}>
                                                <Grid container spacing={1}  sm={12} style={{fontSize: 17}} >
                                                    <Grid item sm={12} className={"header-title"}>
                                                        <h3>Informações do Cliente</h3>
                                                    </Grid>
                                                    <Grid item sm={6}><b >Nome: </b><span>{name}</span></Grid>
                                                    <Grid item sm={6}><b >Email: </b><a style={{color: 'black'}} href={"mailTo:"+email}>{email}</a></Grid>
                                                    <Grid item sm={6}><b >Numero Telefone: </b><span>{phoneNumber}</span></Grid>
                                                </Grid>
                                                <Grid container spacing={1} sm={12} style={{fontSize: 17}} >
                                                    <Grid item sm={12} className={"header-title"}>
                                                        <h3>Parametros Tecnicos</h3>
                                                    </Grid>
                                                    {info!==undefined?(
                                                        <>
                                                            <Grid item sm={6}><b>DR ID: </b><span>{info.Info.DRID}</span></Grid>
                                                            <Grid item sm={6}><b>Password: </b><span>{info.Info.Token}</span></Grid>
                                                        </>
                                                        ):null}

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                            </Stepper>
                            <div className={classes.actionsContainer}>
                                <div>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleBack}
                                        className={classes.button}
                                        disabled={disableButtons}
                                    >
                                        Voltar
                                    </Button>
                                    {activeStep!==3?(
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={disableButtons}
                                        >
                                            Avançar
                                        </Button>
                                    ):(
                                        <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={registerCustomer}
                                        className={classes.button}
                                        disabled={disableButtons}
                                        >
                                        Registar
                                    </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}