import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import React from "react";
import {useHistory} from "react-router-dom";
import GroupIcon from '@material-ui/icons/Group';

const MenuList=()=> {
    const history = useHistory();
    return (
        <List>
            <ListItem button onClick={() => history.push('/customer/add')}>
                <ListItemIcon><GroupAddIcon style={{color: "#fff"}}/></ListItemIcon>
                <ListItemText primary={"Criar cliente"}/>
            </ListItem>
            <ListItem button onClick={() => history.push('/customer')}>
                <ListItemIcon><GroupIcon style={{color: "#fff"}}/></ListItemIcon>
                <ListItemText primary={"Listar cliente"}/>
            </ListItem>
        </List>
    )
}

export default MenuList;